import React from "react"
import { useRecoilState } from "recoil"
import { collapsedState } from "../../utils/recoil-atoms"
import { Link, StaticQuery, graphql } from "gatsby"
import logo from "../../assets/images/logo.png"

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    let elementId = document.getElementById("navbar")
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky")
      } else {
        elementId.classList.remove("is-sticky")
      }
    })
    // window.scrollTo(0, 100)
  })

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  const parentLinks = parent => {
    if (parent.children) {
      return (
        <li className="nav-item">
          <Link
            to={parent.link}
            activeClassName="active"
            onClick={() => setCollapsed(true)}
            className="nav-link"
          >
            {parent.name}
            <i className="bx bx-chevron-down"></i>
          </Link>
          {childLinks(parent.children)}
        </li>
      )
    } else {
      return (
        <li className="nav-item">
          <Link to={parent.link} activeClassName="active" className="nav-link">
            {parent.name}
          </Link>
        </li>
      )
    }
  }

  const childLinks = children => {
    if (children) {
      return (
        <ul className="dropdown-menu">
          {children.map(link => (
            <li className="nav-item">
              <Link
                to={link.link}
                activeClassName="active"
                onClick={() => setCollapsed(true)}
                className="nav-link"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      )
    }
  }

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" width="230px" />
              </Link>
              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>
              <StaticQuery
                query={graphql`
                  query amenuLinkQuery {
                    site {
                      siteMetadata {
                        menuLinks {
                          name
                          link
                          children {
                            name
                            link
                          }
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <div className={classOne} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                      {data.site.siteMetadata.menuLinks.map(link =>
                        parentLinks(link)
                      )}
                    </ul>
                  </div>
                )}
              />
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar

// {/* <li className="nav-item">
//             <Link
//               to="/"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               IT Services
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/seo-agency"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               SEO Agency
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/data-science-ml-company"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Data Science ML Company
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/data-analytics-ai-startup"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Data Analytics & AI Startup
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/digital-marketing-agency"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Digital Marketing Agency
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/data-science-online-courses"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Data Science Online Courses
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/big-data-analysis-startup"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Big Data Analysis Startup
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/data-analytics-ml-consulting"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Data Analytics ML Consulting
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               to="/machine-learning-ai-solutions"
//               activeClassName="active"
//               onClick={() => setCollapsed(true)}
//               className="nav-link"
//             >
//               Machine Learning AI Solutions
//             </Link>
//           </li> */}

// {/*
//         <div className="option-item">
//           <Link
//             to="/contact"
//             activeClassName="active"
//             onClick={() => setCollapsed(true)}
//             className="default-btn"
//           >
//             <i className="flaticon-right"></i> Get Started <span></span>
//           </Link>
//         </div> */}
