import React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/logo.png"
import Footer from "../components/App/Footer"
import Navbar from "../components/App/DynamicPageNavbar"
import Layout from "../components/App/DocsLayout"
import MainBanner from "../components/Index/MainBanner"
import OurFeatures from "../components/Index/OurFeatures"
import OurServices from "../components/Index/OurServices"
import OurSolutions from "../components/Index/OurSolutions"
import Partner from "../components/Index/Partner"
import Pricing from "../components/Index/Pricing"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import RecentProjects from "../components/Index/RecentProjects"
import TeamMember from "../components/Index/TeamMember"
import Testimonials from "../components/Index/Testimonials"
import AboutArea from "../components/Index/AboutArea"
import FeaturedServices from "../components/Index/FeaturedServices"
import FunFacts from "../components/Index/FunFacts"
import HowItWork from "../components/Index/HowItWork"
import WhyChooseUs from "../components/Index/WhyChooseUs"

const ComingSoon = () => {
  const [days, setDays] = React.useState("")
  const [hours, setHours] = React.useState("")
  const [minutes, setMinutes] = React.useState("")
  const [seconds, setSeconds] = React.useState("")

  React.useEffect(() => {
    const interval = setInterval(() => {
      commingSoonTime()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const commingSoonTime = () => {
    let endTime = new Date("October 01, 2022 17:00:00 PDT")
    let endTimeParse = Date.parse(endTime) / 1000
    let now = new Date()
    let nowParse = Date.parse(now) / 1000
    let timeLeft = endTimeParse - nowParse
    let days = Math.floor(timeLeft / 86400)
    let hours = Math.floor((timeLeft - days * 86400) / 3600)
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60)
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    )
    if (hours < "10") {
      hours = "0" + hours
    }
    if (minutes < "10") {
      minutes = "0" + minutes
    }
    if (seconds < "10") {
      seconds = "0" + seconds
    }
    setDays(days)
    setHours(hours)
    setMinutes(minutes)
    setSeconds(seconds)
  }

  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <OurSolutions />
      <Partner />
      <AboutArea />
      <FunFacts />
      <OurServices />
      {/* <WhyChooseUs />

      <OurFeatures /> */}
      <Footer />
    </Layout>
  )
}

export default ComingSoon
