import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"

const OurSolutions = () => {
  return (
    <section className="solutions-area ptb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="star" />
            Our Solutions
          </span>
          <h2>We increase the ROI of your existing investments</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p> */}
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>
                Atlassian Apps
                {/* <Link to="/service-details">Atlassian Apps</Link> */}
              </h3>

              <p>
                Atlassian is a global software company with over 240,000
                customers. Atlassian is building the next generation of team
                productivity tools.
              </p>

              {/* <Link to="/service-details" className="view-details-btn">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>
                Salesforce Apps
                {/* <Link to="/service-details">Salesforce Apps</Link> */}
              </h3>

              <p>
                The world's leading CRM. More than 150,000 companies use
                Salesforce CRM to grow their businesses by strengthening
                customer relationships.
              </p>

              {/* <Link to="/service-details" className="view-details-btn">
                View Details
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>
                Custom Builds
                {/* <Link to="/service-details">Custom Builds</Link> */}
              </h3>
              <p>
                Let K10 be your force multiplier. We will build custom
                functionality into your existing Enterprise Software Platform
                making your existing investment go further.
              </p>
              {/* <Link to="/service-details" className="view-details-btn">
                View Details
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSolutions
