import React from "react"
import { Link } from "gatsby"
import service3 from "../../assets/images/services/service3.png"
import starIcon from "../../assets/images/star-icon.png"
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png"
import shape11 from "../../assets/images/shape/vector-shape11.png"

const AboutArea = () => {
  return (
    <section className="about-area pb-100 bg-f1f8fb">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={service3} alt="about" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <h2>We Bring Missing Features to Your Favorite Tools</h2>
                <p>
                  Whether it's Salesforce, Jira, or ServiceNow. Your
                  organizaiton likely has at least one platform that hosts a
                  majority of your business processes. We find that no matter
                  which platform you use, you have at least one underserved team
                  due to missing functionality. Thats where K10 comes in.
                </p>

                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    Niche Functionality
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Widgets
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Integrations
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Custom Field Types
                    <img src={shape2} alt="about" />
                  </li>
                </ul>
                <p>
                  Bring your teams closer together. Build the missing workflows
                  into your existing platform so that your organizaiton can
                  experience the benefits of a single source of truth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape11">
        <img src={shape11} alt="about" />
      </div>
    </section>
  )
}

export default AboutArea
